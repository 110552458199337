






























import { Component, Vue } from "vue-property-decorator";
import { Action, State, Getter } from 'vuex-class'
import SignIn from '@/components/auth/SignIn.vue'
import SignUp from '@/components/auth/SignUp.vue'
import { IAccount, IAuthUser, IError } from '@/types/interfaces'
import User from '@/models/User'
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/plugins/utils'
import EmptyState from '@/components/templates/EmptyState.vue'

@Component({
  components: {
    SignIn, SignUp, EmptyState
  }
})
export default class AuthPage extends Vue {
  private type: string = 'register'
  private loading: Boolean = false
  private authModal: Boolean = false
  @Getter('context/getConsumerName') consumerName: string;
  @State(state => state.users.currentUser) currentUser: User;
  @Action('register', { namespace: 'users' }) register: any;
  @Action('login', { namespace: 'users' }) login: any;
  @Action('account', { namespace: 'shopify' }) fetchShopifyAccount: any;
  @Action('fetchCounts', { namespace: 'redProducts' }) fetchCounts: any;

  mounted() {
    if(this.currentUser) {
      this.$router.push({name: 'index' })
    }
  }

  submitRegister(user: IAuthUser) {
    this.loading = true
    this.register(user).then((result: User) => {
      this.$router.push({ name: 'shopify-connect' })
    }).catch((err: IError) => {
      this.$notify({
        type: 'error',
        title: 'Registration error',
        text: err.message
      })
    }).finally(() => {
      this.loading = false
    })
  }

  submitLogin(user: IAuthUser) {
    this.loading = true
    this.login(user).then((result: User) => {

      this.fetchShopifyAccount().then((account: IAccount) => {
        if(account) {
          if(account.consumer) {
            this.fetchCounts()
            this.$router.push({ name: 'index' })
          } else if(Utils.inIframe() && !account.provider) {
            this.$router.push({ name: 'shopify-connect' })
          } else {
            this.$router.push({ name: 'index' })
          }
        } else {
          if(Utils.inIframe()) {
            this.$router.push({ name: 'shopify-connect' })
          } else {
            this.$router.push({ name: 'index' })
          }
        }
      }).catch((error: IError) => {
        this.$notify({
          type: 'error',
          text: error.message
        })
      })
      
    }).catch((error: IError) => {
      this.$notify({ type: 'error', title: 'Login error', text: error.message })
    }).finally(() => {
      this.loading = false
    })
  }
}

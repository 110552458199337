let utils = {
  inIframe: () => {

    if(typeof window === 'undefined') {
      return null
    }
    else {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  }
}

export default utils